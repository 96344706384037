<template>
  <base-section id="k-d-a-project-detail-description" top-inner-shadow space="150">
    <v-container>
      <v-row :class="`${g_bLowerBr ? 'px-3' : ''}`">
        <v-col cols="12" lg="6" md="6" style="flex-direction: column; display: flex">
          <div :class="`kda-ts-16pt nunito wt-regular`">
            {{ currentProject.strTitle }}
          </div>
          <div :class="`kda-ts-${g_bLowerBr ? 28 : 46}pt wt-extrabold`">
            {{ currentProject.strSubtitle }}
          </div>
        </v-col>
        <v-col v-if="g_bHighestBr" lg="1" />
        <v-col v-else-if="g_bBaseBr" lg="1" />
        <v-col v-else-if="g_bHigherBr" lg="1" />
        <v-col cols="12" lg="5" md="5" :class="`px-${g_bLowerBr || g_bBaseBr ? 4 : 0} flex-center`">
          <div>
            <component
              :is="`p`"
              :class="`kda-ts-${g_bLowerBr ? 12 : 20}pt nunito wt-regular`"
              v-html="currentProject.strDesc"
            />
          </div>
        </v-col>
      </v-row>
      <div style="height: 70px" />
      <v-row justify="center" :style="{ padding: g_bLowerBr ? '0px 40px' : '0px 80px' }">
        <v-col
          :cols="`${g_bLowerBr ? 12 : 4}`"
          class="column-center"
          :style="`alignItems: ${g_bLowerBr ? 'flex-start' : 'flex-end'}`"
        >
          <div class="text-row-center">
            <div class="kda-ts-46pt wt-extrabold">
              {{ currentProject.numScoping }}
            </div>
            <div class="kda-ts-16pt nunito wt-regular ml-4">{{ m_strTimeline }}</div>
          </div>
        </v-col>
        <v-col
          :cols="`${g_bLowerBr ? 12 : 4}`"
          class="column-center"
          :style="`alignItems: ${g_bLowerBr ? 'flex-start' : ''}`"
          style="margin: auto"
        >
          <div class="text-row-center">
            <div class="kda-ts-46pt wt-extrabold">
              {{ currentProject.numDevelopment }}
            </div>
            <div class="kda-ts-16pt nunito wt-regular ml-4">{{ m_strDevelopment }}</div>
          </div>
        </v-col>
        <v-col
          :cols="`${g_bLowerBr ? 12 : 4}`"
          class="column-center"
          style="align-items: flex-start"
        >
          <div class="text-row-center">
            <div class="kda-ts-46pt wt-extrabold">
              {{ currentProject.numTickets }}
            </div>
            <div class="kda-ts-16pt nunito wt-regular ml-4">{{ m_strTaskCompleted }}</div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
import BaseBreakpoint from '@/mixins/base-breakpoint';

export default {
  name: 'KDAProjectDetailDescription',

  mixins: [BaseBreakpoint],

  data: () => ({
    strBack: 'Kembali ke Teknologi'
  }),

  computed: {
    m_strTimeline() {
      return this.$vuetify.lang.t('$vuetify.kda.project.timeline');
    },
    m_strDevelopment() {
      return this.$vuetify.lang.t('$vuetify.kda.project.development');
    },
    m_strTaskCompleted() {
      return this.$vuetify.lang.t('$vuetify.kda.project.task-completed');
    },
    arrProject() {
      return [
        {
          strType: 'PT. Citra Kreasi Makmur',
          strTitle: this.$vuetify.lang.t('$vuetify.kda.project.detail.item-wayfinder.name'),
          strSubtitle: this.$vuetify.lang.t('$vuetify.kda.project.detail.item-wayfinder.subtitle'),
          strDesc: this.$vuetify.lang.t('$vuetify.kda.project.detail.item-wayfinder.desc'),
          strLink: 'wayfinder',
          numScoping: 5,
          numDevelopment: 25,
          numTickets: 300
        },
        {
          strType: 'PT. Asuransi AXA Indonesia',
          strTitle: this.$vuetify.lang.t('$vuetify.kda.project.detail.item-axa.name'),
          strSubtitle: this.$vuetify.lang.t('$vuetify.kda.project.detail.item-axa.subtitle'),
          strDesc: this.$vuetify.lang.t('$vuetify.kda.project.detail.item-axa.desc'),
          strLink: 'axa',
          numScoping: 5,
          numDevelopment: 25,
          numTickets: 300
        },
        {
          strType: 'PT. Citra Kreasi Makmur',
          strTitle: this.$vuetify.lang.t('$vuetify.kda.project.detail.item-load-container.name'),
          strSubtitle: this.$vuetify.lang.t(
            '$vuetify.kda.project.detail.item-load-container.subtitle'
          ),
          strDesc: this.$vuetify.lang.t('$vuetify.kda.project.detail.item-load-container.desc'),
          strLink: 'load-container',
          numScoping: 5,
          numDevelopment: 25,
          numTickets: 300
        },
        {
          strType: 'PT. Citra Kreasi Makmur',
          strTitle: this.$vuetify.lang.t('$vuetify.kda.project.detail.item-sqm-chatbot.name'),
          strSubtitle: this.$vuetify.lang.t(
            '$vuetify.kda.project.detail.item-sqm-chatbot.subtitle'
          ),
          strDesc: this.$vuetify.lang.t('$vuetify.kda.project.detail.item-sqm-chatbot.desc'),
          strLink: 'sqm-chatbot',
          imgSrc: require(`@/assets/photos/home-project-4.png`),
          numScoping: 5,
          numDevelopment: 25,
          numTickets: 300
        },
        {
          strType: 'PT. Citra Kreasi Makmur',
          strTitle: this.$vuetify.lang.t('$vuetify.kda.project.detail.item-price-fetcher.name'),
          strSubtitle: this.$vuetify.lang.t(
            '$vuetify.kda.project.detail.item-price-fetcher.subtitle'
          ),
          strDesc: this.$vuetify.lang.t('$vuetify.kda.project.detail.item-price-fetcher.desc'),
          strLink: 'price-fetcher',
          imgSrc: require(`@/assets/photos/home-project-5.png`),
          numScoping: 5,
          numDevelopment: 25,
          numTickets: 300
        }
      ];
    },
    currentProject() {
      const currentProject = this.arrProject.filter(
        (item) => this.$route.params.strProject === item.strLink
      )[0];

      return currentProject;
    }
  },

  methods: {
    navBack() {
      this.$router.back();
    }
  }
};
</script>

<style scoped lang="scss">
@import 'src/styles/variables.scss';

.col-reverse {
  display: flex !important;
  flex-direction: column-reverse !important;
}

.text-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-center {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
}

.row-wrapper {
  display: flex;
  flex-direction: row;
}

.background-tech-list {
  background-color: #fbfbfb;
}

.cursor-pointer {
  cursor: pointer;
}

.img-container {
  overflow: hidden;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;

  img {
    &.right {
      height: 460px;
      width: 460px;
    }

    &.top {
      height: fit-content;
      width: 290px;
      position: relative;
      margin-top: 50px;
    }
  }
}
</style>
